import IconLink from "./icon-link";
import * as React from "react";
import PropTypes from "prop-types";
import {useInView} from "react-intersection-observer";
import Image from "react-bootstrap/Image";

const ProductPreview = ({icon, title, description, url, button}) => {

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  return (
    <div ref={ref} className={`d-flex flex-column flex-sm-row text-center text-sm-left my-5 homepage-product-preview ${inView ? 'in-view' : ''}`} style={{maxWidth: '640px'}}>
      <div className="mr-sm-4 homepage-product-icon">
        <div className="brush brush-center brush-contain" style={{padding: '10px', margin: '-10px'}}>
          <Image src={icon.url} width={100} height={100} className="icon-img" alt={icon.alt}/>
        </div>
      </div>
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="text-center text-sm-left text-lg-right">
          <IconLink to={url} icon="FaArrowRight" size="sm">
            {button}
          </IconLink>
        </div>
      </div>
    </div>
  );
}

ProductPreview.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  button: PropTypes.string
}

export default ProductPreview;
