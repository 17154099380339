import IconLink from "./icon-link";
import * as React from "react";
import PropTypes from "prop-types";
import {useInView} from "react-intersection-observer";
import RichText from "prismic-reactjs/src/Component";
import GatsbyLink from "./gatsby-link";

const PagePreview = ({title, description, url, button}) => {

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div ref={ref} className={`page-preview text-center text-sm-left my-5 ${inView ? 'in-view' : ''}`} style={{maxWidth: '900px'}}>
      <h2 className="page-preview-title">
        <RichText render={title} serializeHyperlink={GatsbyLink}/>
      </h2>
      <RichText render={description} serializeHyperlink={GatsbyLink}/>
      <div className="text-center text-sm-right">
        <IconLink to={url} icon='FaArrowRight'>
          {button}
        </IconLink>
      </div>
    </div>
  );
}

PagePreview.propTypes = {
  title: PropTypes.array,
  description: PropTypes.array,
  url: PropTypes.string,
  button: PropTypes.string
}

export default PagePreview;
