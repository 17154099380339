import * as React from "react"
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RichText from "prismic-reactjs/src/Component";
import Jumbotron from "react-bootstrap/Jumbotron";
import IconLink from "../components/icon-link";
import Carousel from "react-bootstrap/Carousel";
import GatsbyLink from "../components/gatsby-link";
import CallToAction from "../components/call-to-action";
import ProductPreview from "../components/product-preview";
import PagePreview from "../components/page-preview";
import {useInView} from "react-intersection-observer";

const IndexPage = ({ data }) => {

  const [productsMoreRef, productsMoreInView, ] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  const content = data.prismicHomepage.data;
  const products = data.allPrismicLeistungen.edges.map(edge => edge.node);

  return (
    <Layout title="Home">
      <section>
        <Jumbotron fluid className="d-flex flex-column justify-content-center pt-0 pt-lg-3">
          <Row className="d-flex flex-column-reverse flex-lg-row">
            <Col lg={8}>
              <div className="h-100 text-lg-left text-center d-flex flex-column justify-content-center">
                { content.titel_intro_anzeigen && <p className="homepage-title-intro">{RichText.asText(content.titel_intro.raw)}</p>}
                <Carousel indicators={false} controls={false}>
                  {content.titel_karussell.map((carouselItem, index) => {
                    return carouselItem.titel_anzeigen &&
                      <Carousel.Item key={`carousel-item-${index}`} interval={carouselItem.duration || 2000}>
                        <div className="d-flex flex-column justify-content-center carousel-title">
                          <h1>{RichText.asText(carouselItem.titel.raw)}</h1>
                        </div>
                      </Carousel.Item>
                  })}
                </Carousel>
                <div className="mb-4 large carousel-subtitle">
                  <RichText render={content.description.raw} serializeHyperlink={GatsbyLink}/>
                </div>
                <Link to={content.banner_cta_link.url}>
                  <Button size="lg">{content.banner_cta_label}</Button>
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3 mb-lg-0 d-flex justify-content-center banner-img-container align-items-start">
                <Image className="banner-img" src={content.titelbild.url} alt={content.titelbild.alt}/>
              </div>
            </Col>
          </Row>
        </Jumbotron>
      </section>
      <section>
        <Row>
          <Col>
            <div className="text-center">
              <h2 className="hyphens-auto">{RichText.asText(content.leistungen_titel.raw)}</h2>
              <RichText render={content.leistungen_beschreibung.raw} serializeHyperlink={GatsbyLink}/>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col>
            <div>
              { products.map((product, index) => {
                return (
                  <ProductPreview key={`product-preview-${index}`}
                                  url={product.url}
                                  icon={product.data.icon}
                                  title={product.data.name.text}
                                  description={product.data.kurzbeschreibung.text}
                                  button={product.data.mehr_button}
                  />
                );
              })}
            </div>
            <div ref={productsMoreRef} className={`text-center align-items-center opacity-0 ${productsMoreInView ? 'slide-up' : ''}`}>
              <p className="mb-0">{content.leistungen_mehr_titel}</p>
              <IconLink to={content.leistungen_link.url} icon="FaArrowRight">
                {content.leistungen_link_label}
              </IconLink>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col>
            { content.page_preview.map(preview => {
              return (
                <PagePreview key={preview.page_link.uid}
                             title={preview.preview_titel.raw}
                             description={preview.preview_beschreibung.raw}
                             button={preview.page_link_label}
                             url={preview.page_link.url}
                />
              );
            })}
          </Col>
        </Row>
      </section>
      <CallToAction/>
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    prismicHomepage {
      data {
        cta_beschreibung {
          raw
        }
        cta_titel {
          raw
        }
        description {
          raw
        }
        leistungen_beschreibung {
          raw
        }
        leistungen_mehr_titel
        leistungen_titel {
          raw
        }
        page_preview {
          page_link {
            url
            uid
          }
          page_link_label
          preview_beschreibung {
            raw
          }
          preview_titel {
            raw
          }
        }
        titel_karussell {
          titel_anzeigen
          titel {
            raw
          }
          duration
        }
        titelbild {
          url
          alt
        }
        titel_intro {
          raw
        }
        titel_intro_anzeigen
        banner_cta_label
        banner_cta_link {
          url
        }
        cta_label
        cta_link {
          url
        }
        leistungen_link {
          url
        }
        leistungen_link_label
      }
    }
    allPrismicLeistungen(sort: {fields: data___order, order: ASC}, limit: 4) {
      edges {
        node {
          data {
            kurzbeschreibung {
              text
            }
            name {
              text
            }
            icon {
              alt
              url
            }
            mehr_button
          }
          uid
          url
        }
      }
    }
  }
`

export default IndexPage
